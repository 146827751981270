<template>
    <section class="overview-container clear">
        <div class="w">
            <div class="overview-left lf">
                <div class="overview-box">
                    <h4 class="title">崇信概况</h4>
                    <div class="text pre" v-html="result.cont"></div>
                    <router-link to="/survey" class="more lr"
                        >【更多详情】</router-link
                    >
                </div>
            </div>
            <div class="overview-right lr">
                <div class="overview-sd">
                    <video
                        :src="videopath"
                        width="100%"
                        height="100%"
                        controls="controls"
                        type="video/mp4"
                        object-fit=" fill"
                    ></video>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { loadSingleConfig,loadVideoConfig, loadPictureConfig } from "@/api/api";
export default {
    created() {
        this.loadDataInfo();
    },
    data() {
        return {
            result: "",
            image: "",
            videopath: ""
        };
    },
    methods: {
        loadDataInfo() {
            // 查询文字
            loadSingleConfig({
                rule: "cx_overview",
            }).then((res) => {
                if (res.success) {
                    this.result = res.result;
                }
            });
            // 查询图片
            loadPictureConfig({
                rule: "index_overview",
            }).then((res) => {
                if (res.success) {
                    this.image = `url(/jeecg-boot/${res.result.theLocal})`;
                }
            });
            // 查询视频
      loadVideoConfig({
        rule: "overview",
      }).then((res) => {
        if (res.success) {
          if (res.result.theType == "上传本地") {
            this.videopath =
              "/jeecg-boot/" + res.result.theLocal;
          } else if (res.result.theType == "外部链接") {
            this.videopath = res.result.theLocal;
          }
        }
      });
        },
    },
};
</script>

<style lang="scss" scoped>
.overview-container {
    padding: 110px 0 0;
    background: url(~@/assets/image/main/white-bg.png) no-repeat fixed top left;
    background-size: 100% 100%;
    .overview-left {
        width: 630px;
        height: 429px;
        background: url(~@/assets/image/main/overview-bg.png) no-repeat top left;
        background-size: 100% 100%;
        color: #333;
        .overview-box {
            padding: 27px;
            .title {
                font-size: 24px;
                font-weight: normal;
                &::after {
                    content: "";
                    display: block;
                    width: 42px;
                    height: 3px;
                    background-color: #6EB400;
                    margin: 16px 0 27px;
                }
            }
            ::v-deep.text {
                color: #666;
                line-height: 32px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 7;
                overflow: hidden;
                height: 200px;
                p {
                    font-size: 14px !important;
                    span {
                        font-size: 14px !important;
                    }
                }
            }
            .more {
                color: #6EB400;
            }
        }
    }
    .overview-right {
        margin-top: 27px;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 2px 9px 0px rgba(125, 124, 123, 0.55);
        .overview-sd {
            width: 504px;
            height: 342px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>