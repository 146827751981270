<template>
  <section class="food-container">
    <div class="w">
      <div class="food-tabs">
        <div class="food-title">
          <img src="@/assets/image/main/food.png" class="food-icon lf" />
          <span class="text lf">崇信美食</span>
        </div>
        <el-tabs v-model="activeName" @tab-click="linkHandler">
          <el-tab-pane label="崇信名吃" name="4">
            <div class="food-list">
              <el-row :gutter="25">
                <el-col :span="8" v-for="item in cxmcfirst" :key="item.id">
                  <div class="food-item">
                    <router-link
                      :to="{
                        path: '/tourism/detail',
                        query: { id: item.id },
                      }"
                    >
                      <img
                        :src="'/jeecg-boot/' + item.theLocal"
                        class="food-image"
                      />
                      <div class="title">
                        <span>{{ item.theTitle }}</span>
                      </div>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="25" style="margin-top: 24px">
                <el-col :span="6" v-for="item in cxmcsecond" :key="item.id">
                  <div class="food-item food-item-second">
                    <router-link
                      :to="{
                        path: '/tourism/detail',
                        query: { id: item.id },
                      }"
                    >
                      <img
                        :src="'/jeecg-boot/' + item.theLocal"
                        class="food-image"
                      />
                      <div class="title">
                        <span>{{ item.theTitle }}</span>
                      </div>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="崇信特产" name="5">
            <div class="food-list">
              <el-row :gutter="25">
                <el-col :span="8"  >
                  <div class="food-video">
                    <video
                        :src="videopath"
                        width="100%"
                        height="100%"
                        controls="controls"
                        type="video/mp4"
                        object-fit=" fill"
                        style="background: #000;"
                    ></video>
                  </div>
                </el-col>
                <el-col :span="8" v-for="item in cxtcfirst" :key="item.id">
                  <div class="food-item">
                    <router-link
                      :to="{
                        path: '/tourism/detail',
                        query: { id: item.id },
                      }"
                    >
                      <img
                        :src="'/jeecg-boot/' + item.theLocal"
                        class="food-image"
                      />
                      <div class="title">
                        <span>{{ item.theTitle }}</span>
                      </div>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="25" style="margin-top: 24px">
                <el-col :span="6" v-for="item in cxtcsecond" :key="item">
                  <div class="food-item food-item-second">
                    <router-link
                      :to="{
                        path: '/tourism/detail',
                        query: { id: item.id },
                      }"
                    >
                      <img
                        :src="'/jeecg-boot/' + item.theLocal"
                        class="food-image"
                      />
                      <div class="title">
                        <span>{{ item.theTitle }}</span>
                      </div>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
        <router-link :to="`/tourism/list/${routerlink}`" class="food-more"
          >></router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { getAction } from "@/api/manage";
import { loadVideoConfig } from "@/api/api";
export default {
  created() {
    this.culChange();
    this.loadIifo();
  },
  computed: {
    cxmcfirst() {
      let arr = [];
      for (let i = 0; i < this.cxmcdata.length; i++) {
        if (i <= 2) {
          arr.push(this.cxmcdata[i]);
        }
      }
      return arr;
    },
    cxmcsecond() {
      let arr = [];
      for (let i = 0; i < this.cxmcdata.length; i++) {
        if (i > 2 && i < 7) {
          arr.push(this.cxmcdata[i]);
        }
      }
      return arr;
    },
    cxtcfirst() {
      let arr = [];
      for (let i = 0; i < this.cxtcdata.length; i++) {
        if (i <= 1) {
          arr.push(this.cxtcdata[i]);
        }
      }
      return arr;
    },
    cxtcsecond() {
      let arr = [];
      for (let i = 0; i < this.cxtcdata.length; i++) {
        if (i > 2 && i < 7) {
          arr.push(this.cxtcdata[i]);
        }
      }
      return arr;
    },
  },
  data() {
    return {
      activeName: "4",
      result: [],
      //崇信名吃
      cxmcdata: [],
      //崇信特产
      cxtcdata: [],
      routerlink: "4",
      videopath: ""
    };
  },
  methods: {
    typeChange(type) {
      let params = {
        theType: type,
        pageSize: 7,
      };
      return getAction("/culture/cxCulture/list", params).then((res) => {
        if (res.success) {
          return res.result.records;
        }
      });
    },
    loadIifo(){
       // 查询视频
      loadVideoConfig({
        rule: "specialty",
      }).then((res) => {
        if (res.success) {
          if (res.result.theType == "上传本地") {
            this.videopath =
              "/jeecg-boot/" + res.result.theLocal;
          } else if (res.result.theType == "外部链接") {
            this.videopath = res.result.theLocal;
          }
        }
      });
    },
    culChange() {
      // 崇信名吃
      this.typeChange(4).then((res) => {
        this.cxmcdata = res;
      });
      // 崇信特产
      this.typeChange(5).then((res) => {
        this.cxtcdata = res;
      });
    },
    linkHandler(tab) {
      this.routerlink = tab.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.food-container {
  background: url(~@/assets/image/main/food-bg.png) no-repeat fixed top left;
  background-size: 100% 100%;
  padding: 46px 0 93px;
  .food-list {
    height: 544px;
    .food-item {
      position: relative;
      height: 292px;
      overflow: hidden;
      .food-image {
        width: 100%;
        height: 292px;
         transition: all 0.6s;
          &:hover {
            transform: scale(1.1);
          }
      }
      .title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 34px;
        line-height: 34px;
        background-color: rgba($color: #333333, $alpha: 0.6);
        color: #fff;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        span {
          padding: 0 11px;
        }
      }
    }
    .food-video{
      position: relative;
      height: 292px;
      overflow: hidden;
    }
    .food-item-second {
      height: 228px;
      .food-image {
        height: 228px;
      }
    }
  }
  .food-tabs {
    position: relative;
    ::v-deep.el-tabs__nav-wrap {
      height: 54px;
      background-color: #fff;
      .el-tabs__nav-scroll {
        width: 400px;
        margin: 6px auto 0;
        .el-tabs__nav {
          .el-tabs__item {
            color: #333333;
            font-size: 16px;
          }
          .el-tabs__item.is-active {
            color: #6EB400;
          }
          .el-tabs__active-bar {
            background-color: #6EB400;
          }
        }
      }
      &::after {
        height: 1px;
        background-color: #dbdbdb;
      }
    }
    .food-title {
      position: absolute;
      top: 9px;
      left: 10px;
      z-index: 1;
      .food-icon {
        margin-top: 4px;
        margin-right: 4px;
      }
      .text {
        font-size: 24px;
        color: #333333;
      }
    }
    .food-more {
      color: #666666;
      position: absolute;
      top: 20px;
      right: 11px;
      z-index: 1;
    }
  }
}
</style>
