<template>
  <section class="participate-container">
    <div class="w">
      <div class="part-top">
        <img src="/image/participate.png" class="part-top-image" />
      </div>
      <div class="navigation">
        <div class="box">
          <img src="@/assets/image/main/participate.png" class="image lf" />
          <span class="text lf">最新游记</span>
          <router-link to="/latesttravel" class=" more lr">></router-link>
        </div>
      </div>
      <div class="answer-box">
        <div class="wh-list">
          <el-row :gutter="22">
            <el-col :span="6" v-for="item in result" :key="item.id">
              <div class="wh-item">
                <router-link
                  :to="{ path: '/latestdata', query: { id: item.id } }"
                >
                  <img :src="`/jeecg-boot/${item.theLocal}`" class="wh-image" />
                  <div class="title">
                    <span>{{ item.theTitle }}</span>
                  </div>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="navigation">
          <div class="box">
            <img src="@/assets/image/main/participate.png" class="image lf" />
            <span class="text lf">投诉建议</span>
            <router-link to="/ComplaintList" class=" more lr">></router-link>
          </div>
        </div>
        <div class="quest-list">
          <el-row :gutter="22">
            <el-col :span="12" v-for="item in load" :key="item.id">
              <div class="quest-item">
                <div class="quest-title clear">
                  <span class="circle ask">问</span>
                  <router-link
                    :to="{ path: '/complaintdata', query: { id: item.id } }"
                    class="text"
                  >
                    {{ item.compReason }}
                  </router-link>
                </div>
                <div class="quest-title clear">
                  <span class="circle ans">答</span>
                  <router-link
                    :to="{ path: '/complaintdata', query: { id: item.id } }"
                    class="text"
                    >{{ item.compReply }}</router-link
                  >
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <router-link to="/complaint" class="complaint-btn"
          >我要投诉</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { getAction } from "@/api/manage";
export default {
  created() {
    this.loadData();
    this.loadinfo();
  },
  data() {
    return {
      result: [],
      load: [],
    };
  },
  methods: {
    loadData() {
      let params = {
        pageSize: 4,
      };
      getAction("/note/tourNote/listAllNote", params).then((res) => {
        if (res.success) {
          this.result = res.result.records;
        }
      });
    },
    loadinfo() {
      let prame = {
        pageSize: 4,
      };
      getAction("/complaint/tourComplaint/openList", prame).then((res) => {
        if (res.success) {
          this.load = res.result.records;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.participate-container {
  background: url(~@/assets/image/main/white-bg.png) no-repeat fixed top left;
  background-size: 100% 100%;
  padding: 44px 0 54px;
  .part-top {
    margin-bottom: 62px;
    .part-top-image {
      width: 100%;
    }
  }
  .navigation {
    height: 53px;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
    .box {
      padding: 10px;
      .image {
        margin-top: 7px;
      }
      .text {
        font-size: 24px;
        color: #333333;
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .more {
        color: #666666;
        margin-top: 11px;
      }
    }
  }
  .answer-box {
    .wh-list {
      height: 228px;
      margin-bottom: 22px;
      .wh-item {
        position: relative;
        height: 228px;
        overflow: hidden;
        .wh-image {
          transition: all 0.6s;
          &:hover {
            transform: scale(1.1);
          }
          width: 100%;
          height: 228px;
        }
        .title {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 34px;
          line-height: 34px;
          background-color: rgba($color: #333333, $alpha: 0.6);
          color: #fff;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            padding: 0 11px;
          }
        }
      }
    }
    .quest-list {
      .quest-item {
        background-color: #f4f4f4;
        border-radius: 6px;
        margin-bottom: 20px;
        padding: 15px;
        .quest-title {
          height: 36px;
          margin-bottom: 20px;
          .circle {
            display: block;
            width: 36px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            color: #fffefe;
            font-size: 16px;
            border-radius: 50%;
            float: left;
            margin-right: 8px;
          }
          .text {
            float: left;
            width: 500px;
            color: #666666;
            line-height: 36px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: all 0.3s;
            &:hover {
              color: #6EB400;
            }
          }
          .ask {
            background-color: #6EB400;
          }
          .ans {
            background-color: #1aa4fb;
          }
        }
        .quest-sm-list {
          border-top: 1px dashed #999999;
          .quest-sm-item {
            padding: 26px 10px;
            border-bottom: 1px dashed #999999;
            .text {
              margin-top: -19px;
              float: left;
              width: 500px;
              color: #666666;
              line-height: 36px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              transition: all 0.3s;
              &:hover {
                color: #6EB400;
              }
            }
          }
        }
      }
    }
    .complaint-btn {
      display: block;
      width: 104px;
      height: 44px;
      line-height: 44px;
      background-color: #6EB400;
      border-radius: 4px;
      margin: 67px auto 0;
      color: #fff;
      text-align: center;
    }
  }
}
</style>
