<template>
    <section class="touris-container clear">
        <div class="w">
            <div class="h-title">
                <div class="text clear">
                    <img src="@/assets/image/main/windmill.png" class="windmill-img lf">
                    <span class="lf">旅游动态</span>
                </div>
            </div>
            <div class="touris-article">
                <ul class="touris-list">
                    <el-row :gutter="31">
                        <el-col :span="8" v-for="item in result" :key="item.id">
                            <li class="touris-item">
                                <router-link :to="{path:'dyDetail',query:{id:item.id}}">
                                    <div class="time">
                                        <p class="date">{{ item.createTime.substring(5,10) }}</p>
                                        <p class="year">{{ item.createTime.substring(0,4) }}</p>
                                    </div>
                                    <h5 class="art-title">{{ item.theTitle }}</h5>
                                    <p class="info">{{ item.theInfo }}</p>
                                    <i class="fa fa-long-arrow-right arrow-right lr"></i>
                                </router-link>
                            </li>
                        </el-col>
                    </el-row>
                </ul>
            </div>
            <router-link to="/dynamic" class="touris-more">更多</router-link>
        </div>
    </section>
</template>

<script>
import { getAction } from '@/api/manage'
export default {
    created() {
        this.loadData()
    },
    data() {
        return {
            result: []
        }
    },
    methods: {
        loadData(){
            getAction('/trend/sysTrend/list',{
                pageSize: 3,
                column: 'createTime',
                order: 'desc', 
            }).then(res => {
                if(res.success){
                    this.result = res.result.records
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.touris-container{
    padding: 67px 0 71px;
    background: url(~@/assets/image/main/white-bg.png) no-repeat fixed top left;
    background-size: 100% 100%;
    .h-title{
        background: url(~@/assets/image/main/moire.png) no-repeat center;
        text-align: center;
        .text{
            font-size: 24px;
            color: #333;
            margin: 0 auto;
            width: 142px;
            .windmill-img{
                margin-right: 12px;
            }
        }
    }
    .touris-article{
        padding: 74px 0 51px;
        .touris-list{
            .touris-item{
                .time{
                    color: #666;
                    padding: 16px 11px;
                    border-bottom: 2px solid #bfbfbf;
                    transition: all .3s;
                    .date{
                        font-size: 24px;
                        margin-bottom: 5px;
                    }
                }
                .art-title{
                    padding: 29px 0 23px;
                    font-size: 16px;
                    color: #333333;
                    font-weight: normal;
                    transition: all .3s;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .info{
                    font-size: 14px;
                    line-height: 26px;
                    color: #999999;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    margin-bottom: 21px;
                    text-align: justify;
                }
                .arrow-right{
                    color: #bfbfbf;
                    font-size: 16px;
                    transition: all .3s;
                }
                &:hover{
                    .time{
                        background-color: #6EB400;
                        color: #fff;
                        border-color: #6EB400;
                    }
                    .art-title{
                        color: #6EB400;
                    }
                    .arrow-right{
                        color: #6EB400;
                    }
                }
            }
        }
    }
    .touris-more{
        display: block;
        width: 122px;
        height: 40px;
        line-height: 40px;
        border: solid 1px #6EB400;
        margin: 0 auto;
        color: #6EB400;
        text-align: center;
        transition: all .3s;
        &:hover{
            background-color: #6EB400;
            color: #fff;
        }
    }
}
</style>