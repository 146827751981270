<template>
  <section class="hotspots-container">
    <div class="w">
      <div class="navigation">
        <div class="box">
          <img src="@/assets/image/main/mountain.png" class="lf" />
          <span class="text lf">热门景点</span>
          <router-link to="/scenicspot" class="more lr">></router-link>
        </div>
      </div>
      <div class="hotspots-article">
        <el-row :gutter="24">
          <el-col :span="19">
            <div class="scenic-spot">
              <el-row :gutter="24" style="margin-bottom: 24px">
                <el-col :span="16">
                  <div class="scenic-spot-item">
                    <img
                      v-if="bigending.theLocal"
                      :src="`/jeecg-boot/${bigending.theLocal}`"
                      class="imgleft"
                    />
                    <img
                      v-else
                      src="@/assets/image/main/zanwutp.png"
                      alt=""
                      class="imgleft"
                    />
                    <div class="title">
                      <span>{{ bigending.theTitle }}</span>
                    </div>
                    <div class="info">
                      <div class="text">
                        <p>{{ bigending.theInfo }}</p>
                        <router-link
                          :to="{
                            path: '/scenicDetail',
                            query: { id: bigending.id },
                          }"
                          class="see-more lr"
                          >【查看详情】</router-link
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="scenic-spot-item">
                   <img v-if="secondending.theLocal" :src="`/jeecg-boot/${secondending.theLocal}`" class="imgleft"/>
                <img v-else src="@/assets/image/main/zanwutp.png" alt="" class="imgleft"/>
                    <div class="title">
                      <span>{{ secondending.theTitle }}</span>
                    </div>
                    <div class="info">
                      <div class="text">
                        <p>{{ secondending.theInfo }}</p>
                        <router-link
                          :to="{
                            path: '/scenicDetail',
                            query: { id: secondending.id },
                          }"
                          class="see-more lr"
                          >【查看详情】</router-link
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="8" v-for="item in smallending" :key="item.id">
                  <div class="scenic-spot-item">
                    <img v-if="item.theLocal" :src="`/jeecg-boot/${item.theLocal}`" class="imgleft"/>
                <img v-else src="@/assets/image/main/zanwutp.png" alt="" class="imgleft"/>
                    <div class="title">
                      <span>{{ item.theTitle }}</span>
                    </div>
                    <div class="info">
                      <div class="text">
                        <p>{{ item.theInfo }}</p>
                        <router-link
                          :to="{
                            path: '/scenicDetail',
                            query: { id: item.id },
                          }"
                          class="see-more lr"
                          >【查看详情】</router-link
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="hot-nav">
              <el-row>
                <el-col :span="12">
                  <router-link to="/touristinfo">
                    <img src="@/assets/image/main/icon/hotspots-1.png" />
                    <span>旅游须知</span>
                  </router-link>
                </el-col>
                <el-col :span="12">
                  <router-link to="/festival">
                    <img src="@/assets/image/main/icon/hotspots-2.png" />
                    <span>旅游节庆</span>
                  </router-link>
                </el-col>
                <el-col :span="12">
                  <router-link to="/traffic">
                    <img src="@/assets/image/main/icon/hotspots-3.png" />
                    <span>交通指南</span>
                  </router-link>
                </el-col>
                <el-col :span="12">
                  <router-link to="/meteor">
                    <img src="@/assets/image/main/icon/hotspots-4.png" />
                    <span>气象服务</span>
                  </router-link>
                </el-col>
                <el-col :span="12">
                  <router-link to="/travelmap">
                    <img src="@/assets/image/main/icon/hotspots-5.png" />
                    <span>旅游地图</span>
                  </router-link>
                </el-col>
                <el-col :span="12">
                  <router-link to="/books">
                    <img src="@/assets/image/main/icon/hotspots-6.png" />
                    <span>书籍光碟</span>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </section>
</template>

<script>
import { getAction } from "@/api/manage";
export default {
  created() {
    this.loadData();
  },
  data() {
    return {
      result: {},
    };
  },
  computed: {
    bigending() {
      if (this.result.length != 0) {
        return this.result[0];
      }
    },
    secondending() {
      if (this.result.length >= 1) {
        return this.result[1];
      }
    },
    smallending() {
      if (this.result.length != 0) {
        let arr = [];
        for (let i = 0; i < this.result.length; i++) {
          if (i > 1 && i < 7) {
            arr.push(this.result[i]);
          }
        }
        return arr;
      }
    },
  },
  methods: {
    loadData() {
      let params = {
        column: "createTime",
        order: "desc",
        pageSize: 5,
      };
      getAction("/scenery/tourScenery/list", params).then((res) => {
        if (res.success) {
          this.result = res.result.records;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hotspots-container {
  padding: 45px 0 67px;
  background: url(~@/assets/image/main/green-bg.png) no-repeat fixed top left;
  background-size: 100% 100%;
  .navigation {
    height: 53px;
    background-color: #fff;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 20px;
    .box {
      padding: 10px;
      .text {
        font-size: 24px;
        color: #333333;
        margin-left: 5px;
      }
      .more {
        color: #666666;
        margin-top: 11px;
      }
    }
  }
  .imgleft {
    &:hover {
    }
  }
  .hotspots-article {
    .scenic-spot {
      img {
        width: 100%;
        height: 188px;
      }
      .scenic-spot-item {
        position: relative;
        height: 188px;
        overflow: hidden;
        transition: all 0.6s;
        .title {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 34px;
          line-height: 34px;
          background-color: rgba($color: #000000, $alpha: 0.6);
          color: #fff;
          font-size: 16px;
          transition: all 0.3s;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            padding: 0 15px;
          }
        }
        .info {
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.6);
          color: #fff;
          font-size: 14px;
          line-height: 28px;
          transition: all 0.3s;
          .text {
            width: 88%;
            margin: 28px auto 0;
            p {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;
              text-align: justify;
              height: 112px;
            }
          }
          .see-more {
            color: #6EB400;
          }
        }
        &:hover {
          .title {
            bottom: -34px;
          }
          .info {
            opacity: 1;
          }
        }
      }
    }
    .hot-nav {
      border-top: 4px solid #6EB400;
      background-color: #fff;
      text-align: center;
      padding: 36px 0 29px;
      height: 330px;
      span {
        display: block;
        margin: 10px 0 25px;
        font-size: 16px;
        color: #666;
      }
    }
  }
}
</style>
