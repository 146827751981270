<template>
  <section class="culture-container">
    <div class="w">
      <div class="culture-nav">
        <el-row :gutter="22">
          <el-col :span="6">
            <router-link to="/hotel">
              <div class="culture-nav-item clear hotel">
                <img
                  src="@/assets/image/main/icon/hotel-icon.png"
                  class="nav-icon lf"
                />
                <div class="text lf">
                  <p class="title">饭店</p>
                  <p class="english">Hotel</p>
                </div>
              </div>
            </router-link>
          </el-col>
          <el-col :span="6">
            <router-link to="/ticketprice">
              <div class="culture-nav-item clear ticket">
                <img
                  src="@/assets/image/main/icon/ticket-icon.png"
                  class="nav-icon lf"
                />
                <div class="text lf">
                  <p class="title">门票</p>
                  <p class="english">Admission Ticket</p>
                </div>
              </div>
            </router-link>
          </el-col>
          <el-col :span="6">
            <router-link to="/travelagency">
              <div class="culture-nav-item clear travel">
                <img
                  src="@/assets/image/main/icon/travel-icon.png"
                  class="nav-icon lf"
                />
                <div class="text lf">
                  <p class="title">旅行社</p>
                  <p class="english">Travel Bureau</p>
                </div>
              </div>
            </router-link>
          </el-col>
          <el-col :span="6">
            <router-link to="/entertainment">
              <div class="culture-nav-item clear ment">
                <img
                  src="@/assets/image/main/icon/ment-icon.png"
                  class="nav-icon lf"
                />
                <div class="text lf">
                  <p class="title">娱乐</p>
                  <p class="english">Entertainment</p>
                </div>
              </div>
            </router-link>
          </el-col>
        </el-row>
      </div>
      <div class="culture-tabs">
        <div class="culture-title">
          <img src="@/assets/image/main/culture.png" class="culture-icon lf" />
          <span class="text lf">崇信文化</span>
        </div>
        <el-tabs v-model="activeName" @tab-click="linkHandler">
          <el-tab-pane label="崇信文物" name="1">
            <div class="culture-list">
              <el-row :gutter="22">
                <el-col :span="6" v-for="item in cxwndata" :key="item.id">
                  <div class="relics-item">
                    <div class="is">
                      <img
                        :src="'/jeecg-boot/' + item.theLocal"
                        class="relics-image"
                      />
                    </div>
                    <div class="title">
                      <span class="text lf">{{ item.theTitle }}</span>
                      <router-link
                        :to="{
                          path: '/tourism/detail',
                          query: { id: item.id },
                        }"
                        class="detl lr"
                        >>></router-link
                      >
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="崇信名人" name="2">
            <div class="culture-list">
              <el-row :gutter="22">
                <el-col :span="6" v-for="item in cxmrdata" :key="item.id">
                  <div class="relics-item">
                    <div class="is">
                      <img
                        :src="'/jeecg-boot/' + item.theLocal"
                        class="relics-image"
                      />
                    </div>
                    <div class="title">
                      <span class="text lf">{{ item.theTitle }}</span>
                      <router-link
                        :to="{
                          path: '/tourism/detail',
                          query: { id: item.id },
                        }"
                        class="detl lr"
                        >>></router-link
                      >
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="崇信民俗" name="3">
            <div class="culture-list">
              <el-row :gutter="22">
                <el-col :span="6" v-for="item in cxmsdata" :key="item.id">
                  <div class="relics-item">
                    <div class="is">
                    <img
                      :src="'/jeecg-boot/' + item.theLocal"
                      class="relics-image"
                    />
                    </div>
                    <div class="title">
                      <span class="text lf">{{ item.theTitle }}</span>
                      <router-link
                        :to="{
                          path: '/tourism/detail',
                          query: { id: item.id },
                        }"
                        class="detl lr"
                        >>></router-link
                      >
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="旅游文学" name="6">
            <div class="culture-list">
              <el-row :gutter="22">
                <el-col :span="6" v-for="item in cxwxdata" :key="item.id">
                  <div class="relics-item">
                    <div class="is">
                    <img
                      :src="'/jeecg-boot/' + item.theLocal"
                      class="relics-image"
                    />
                    </div>
                    <div class="title">
                      <span class="text lf">{{ item.theTitle }}</span>
                      <router-link
                        :to="{
                          path: '/tourism/detail',
                          query: { id: item.id },
                        }"
                        class="detl lr"
                        >>></router-link
                      >
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
        <router-link :to="`/tourism/list/${routerlink}`" class="culture-more"
          >></router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
import { getAction } from "@/api/manage";
export default {
  components: {},
  created() {
    this.culChange();
  },

  data() {
    return {
      activeName: "1",
      result: [],
      // 崇信文物
      cxwndata: [],
      // 崇信名人
      cxmrdata: [],
      // 崇信民俗
      cxmsdata: [],
      // 崇信文学
      cxwxdata: [],
      routerlink: "1",
    };
  },
  methods: {
    typeChange(type) {
      let params = {
        theType: type,
        pageSize: 4,
      };
      return getAction("/culture/cxCulture/list", params).then((res) => {
        if (res.success) {
          return res.result.records;
        }
      });
    },
    culChange() {
      // 崇信文物
      this.typeChange(1).then((res) => {
        this.cxwndata = res;
      });
      // 崇信名人
      this.typeChange(2).then((res) => {
        this.cxmrdata = res;
      });
      // 崇信民俗
      this.typeChange(3).then((res) => {
        this.cxmsdata = res;
      });
      // 崇信文学
      this.typeChange(6).then((res) => {
        this.cxwxdata = res;
      });
    },
    linkHandler(tab) {
      this.routerlink = tab.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.culture-container {
  background: url(~@/assets/image/main/culture-bg.png) no-repeat;
  background-size: 100% 100%;
  .culture-nav {
    padding: 44px 0 48px;
    .culture-nav-item {
      padding: 36px 25px;
      color: #fff;
      
      .nav-icon {
        margin-right: 14px;
      }
      .text {
        font-weight: bold;
        .title {
          font-size: 18px;
        }
        .english {
          margin-top: 6px;
        }
      }
    }
    .hotel {
      background-color: #ff7f3c;
    }
    .ticket {
      background-color: #1aa4fb;
    }
    .travel {
      background-color: #f37ed0;
    }
    .ment {
      background-color: #00d6bd;
    }
  }
  .culture-tabs {
    padding: 0 0 77px;
    position: relative;
    .culture-list {
      height: 282px;
      .relics-item {
        border: solid 1px #dbdbdb;
        overflow: hidden;
            .is {
              width: 274px;
              height: 228px;
              overflow: hidden;
            }
        .relics-image {
          width: 100%;
          height: 228px;
          transition: all 0.6s;
          &:hover {
            transform: scale(1.1);
          }
        }
        .title {
          height: 48px;
          line-height: 48px;
          padding: 0 12px;
          .text {
            font-size: 16px;
            color: #333333;
            width: 210px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    ::v-deep.el-tabs__nav-wrap {
      height: 54px;
      .el-tabs__nav-scroll {
        width: 400px;
        margin: 0 auto;
        .el-tabs__nav {
          .el-tabs__item {
            color: #333333;
            font-size: 16px;
          }
          .el-tabs__item.is-active {
            color: #6EB400;
          }
          .el-tabs__active-bar {
            background-color: #6EB400;
          }
        }
      }
      &::after {
        height: 1px;
        background-color: #dbdbdb;
      }
    }
    .culture-title {
      position: absolute;
      top: 5px;
      left: 10px;
      .culture-icon {
        margin-top: 4px;
        margin-right: 4px;
      }
      .text {
        font-size: 24px;
        color: #333333;
      }
    }
    .culture-more {
      color: #666666;
      position: absolute;
      top: 20px;
      right: 11px;
    }
  }
}
</style>
