var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hotspots-container"},[_c('div',{staticClass:"w"},[_c('div',{staticClass:"navigation"},[_c('div',{staticClass:"box"},[_c('img',{staticClass:"lf",attrs:{"src":require("@/assets/image/main/mountain.png")}}),_c('span',{staticClass:"text lf"},[_vm._v("热门景点")]),_c('router-link',{staticClass:"more lr",attrs:{"to":"/scenicspot"}},[_vm._v(">")])],1)]),_c('div',{staticClass:"hotspots-article"},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":19}},[_c('div',{staticClass:"scenic-spot"},[_c('el-row',{staticStyle:{"margin-bottom":"24px"},attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":16}},[_c('div',{staticClass:"scenic-spot-item"},[(_vm.bigending.theLocal)?_c('img',{staticClass:"imgleft",attrs:{"src":("/jeecg-boot/" + (_vm.bigending.theLocal))}}):_c('img',{staticClass:"imgleft",attrs:{"src":require("@/assets/image/main/zanwutp.png"),"alt":""}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.bigending.theTitle))])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(_vm.bigending.theInfo))]),_c('router-link',{staticClass:"see-more lr",attrs:{"to":{
                          path: '/scenicDetail',
                          query: { id: _vm.bigending.id },
                        }}},[_vm._v("【查看详情】")])],1)])])]),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"scenic-spot-item"},[(_vm.secondending.theLocal)?_c('img',{staticClass:"imgleft",attrs:{"src":("/jeecg-boot/" + (_vm.secondending.theLocal))}}):_c('img',{staticClass:"imgleft",attrs:{"src":require("@/assets/image/main/zanwutp.png"),"alt":""}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.secondending.theTitle))])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(_vm.secondending.theInfo))]),_c('router-link',{staticClass:"see-more lr",attrs:{"to":{
                          path: '/scenicDetail',
                          query: { id: _vm.secondending.id },
                        }}},[_vm._v("【查看详情】")])],1)])])])],1),_c('el-row',{attrs:{"gutter":24}},_vm._l((_vm.smallending),function(item){return _c('el-col',{key:item.id,attrs:{"span":8}},[_c('div',{staticClass:"scenic-spot-item"},[(item.theLocal)?_c('img',{staticClass:"imgleft",attrs:{"src":("/jeecg-boot/" + (item.theLocal))}}):_c('img',{staticClass:"imgleft",attrs:{"src":require("@/assets/image/main/zanwutp.png"),"alt":""}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.theTitle))])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(item.theInfo))]),_c('router-link',{staticClass:"see-more lr",attrs:{"to":{
                          path: '/scenicDetail',
                          query: { id: item.id },
                        }}},[_vm._v("【查看详情】")])],1)])])])}),1)],1)]),_c('el-col',{attrs:{"span":5}},[_c('div',{staticClass:"hot-nav"},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/touristinfo"}},[_c('img',{attrs:{"src":require("@/assets/image/main/icon/hotspots-1.png")}}),_c('span',[_vm._v("旅游须知")])])],1),_c('el-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/festival"}},[_c('img',{attrs:{"src":require("@/assets/image/main/icon/hotspots-2.png")}}),_c('span',[_vm._v("旅游节庆")])])],1),_c('el-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/traffic"}},[_c('img',{attrs:{"src":require("@/assets/image/main/icon/hotspots-3.png")}}),_c('span',[_vm._v("交通指南")])])],1),_c('el-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/meteor"}},[_c('img',{attrs:{"src":require("@/assets/image/main/icon/hotspots-4.png")}}),_c('span',[_vm._v("气象服务")])])],1),_c('el-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/travelmap"}},[_c('img',{attrs:{"src":require("@/assets/image/main/icon/hotspots-5.png")}}),_c('span',[_vm._v("旅游地图")])])],1),_c('el-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":"/books"}},[_c('img',{attrs:{"src":require("@/assets/image/main/icon/hotspots-6.png")}}),_c('span',[_vm._v("书籍光碟")])])],1)],1)],1)])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }