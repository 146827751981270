<template>
    <div>
        <PCindex v-if="!ismobile" />
        <WAPindex v-else />
    </div>
</template>

<script>
import PCindex from './Index'
import WAPindex from './wap/Index'
export default {
    components: {
        PCindex,
        WAPindex
    },
    created() {
        if(this._isMobile()){
            this.ismobile = true
        }
    },
    data() {
        return {
            ismobile: false
        }
    },
    methods: {
        // 判断手机端
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        }
    },
}
</script>