var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"food-container"},[_c('div',{staticClass:"w"},[_c('div',{staticClass:"food-tabs"},[_vm._m(0),_c('el-tabs',{on:{"tab-click":_vm.linkHandler},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"崇信名吃","name":"4"}},[_c('div',{staticClass:"food-list"},[_c('el-row',{attrs:{"gutter":25}},_vm._l((_vm.cxmcfirst),function(item){return _c('el-col',{key:item.id,attrs:{"span":8}},[_c('div',{staticClass:"food-item"},[_c('router-link',{attrs:{"to":{
                      path: '/tourism/detail',
                      query: { id: item.id },
                    }}},[_c('img',{staticClass:"food-image",attrs:{"src":'/jeecg-boot/' + item.theLocal}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.theTitle))])])])],1)])}),1),_c('el-row',{staticStyle:{"margin-top":"24px"},attrs:{"gutter":25}},_vm._l((_vm.cxmcsecond),function(item){return _c('el-col',{key:item.id,attrs:{"span":6}},[_c('div',{staticClass:"food-item food-item-second"},[_c('router-link',{attrs:{"to":{
                      path: '/tourism/detail',
                      query: { id: item.id },
                    }}},[_c('img',{staticClass:"food-image",attrs:{"src":'/jeecg-boot/' + item.theLocal}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.theTitle))])])])],1)])}),1)],1)]),_c('el-tab-pane',{attrs:{"label":"崇信特产","name":"5"}},[_c('div',{staticClass:"food-list"},[_c('el-row',{attrs:{"gutter":25}},[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"food-video"},[_c('video',{staticStyle:{"background":"#000"},attrs:{"src":_vm.videopath,"width":"100%","height":"100%","controls":"controls","type":"video/mp4","object-fit":" fill"}})])]),_vm._l((_vm.cxtcfirst),function(item){return _c('el-col',{key:item.id,attrs:{"span":8}},[_c('div',{staticClass:"food-item"},[_c('router-link',{attrs:{"to":{
                      path: '/tourism/detail',
                      query: { id: item.id },
                    }}},[_c('img',{staticClass:"food-image",attrs:{"src":'/jeecg-boot/' + item.theLocal}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.theTitle))])])])],1)])})],2),_c('el-row',{staticStyle:{"margin-top":"24px"},attrs:{"gutter":25}},_vm._l((_vm.cxtcsecond),function(item){return _c('el-col',{key:item,attrs:{"span":6}},[_c('div',{staticClass:"food-item food-item-second"},[_c('router-link',{attrs:{"to":{
                      path: '/tourism/detail',
                      query: { id: item.id },
                    }}},[_c('img',{staticClass:"food-image",attrs:{"src":'/jeecg-boot/' + item.theLocal}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.theTitle))])])])],1)])}),1)],1)])],1),_c('router-link',{staticClass:"food-more",attrs:{"to":("/tourism/list/" + _vm.routerlink)}},[_vm._v(">")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"food-title"},[_c('img',{staticClass:"food-icon lf",attrs:{"src":require("@/assets/image/main/food.png")}}),_c('span',{staticClass:"text lf"},[_vm._v("崇信美食")])])}]

export { render, staticRenderFns }