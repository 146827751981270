<template>
  <section class="whool-box clear">
    <section class="swiper-container pc-wheel-container">
      <div class="swiper-wrapper">
        <div v-for="item in tp" :key="item.id" class="swiper-slide">
          <a :href="item.theLink" target="_blank">
            <img :src="'/jeecg-boot/' + item.theLocal" class="banner-img" />
          </a>
        </div>
      </div>
      <!-- <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div> -->
    </section>
    <div class="search-box" v-if="search">
      <el-input
        class="query-input"
        placeholder="请输入搜索内容"
        v-model="queryvalue"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
        <el-button slot="append" class="query-btn" @click="querykeyword">搜索</el-button>
      </el-input>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.min.css";
import { getAction } from "@/api/manage";
export default {
  mounted() {
    new Swiper(".pc-wheel-container", {
      autoplay: 5000,
      paginationClickable: true,
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
    });
    this.loadDataTP();
  },
  data() {
    return {
      queryvalue: "",
      tp: {},
      search: false,
    };
  },
  methods: {
    loadDataTP() {
      this.getAction("/lbt/sysLbt/list").then((res) => {
        if (res.success) {
          this.tp = res.result.records;
          this.search = true;
        } 
      });
    },
    querykeyword() {
        if(this.queryvalue==''){
            alert('请输入搜索内容')
        }else{
            this.$router.push({
                path: '/retrieval',
                query: {
                    keyword: this.queryvalue
                }
            })
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.whool-box {
  position: relative;
  .banner-img {
    width: 100%;
  }
  .search-box {
    width: 100%;
    height: 142px;
    background-color: rgba($color: #000000, $alpha: 0.6);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 10;
    .query-input {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 424px;
      height: 44px;
      ::v-deep .el-input__inner {
        border: solid 1px #6EB400;
      }
      ::v-deep .el-input-group__append {
        background-color: transparent;
        border-radius: 0;
        border: none;
        vertical-align: initial;
      }
      .query-btn {
        width: 84px;
        height: 40px;
        background-color: #6EB400;
        color: #fff;
        border-radius: 0 5px 5px 0;
        transition: all 0.5s;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
