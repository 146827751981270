<template>
    <div>
		<Header class="hide" />
    	<Headers class="dispy" />
		<!-- 轮播图 -->
		<Whool />
		<!-- 崇信概况 -->
		<Overview />
		<!-- 旅游动态 -->
		<Tourismdyc />
		<!-- 热门景点 -->
		<Hotspots />
		<!-- 崇信文化 -->
		<Culture />
		<!-- 崇信美食 -->
		<Food />
		<!-- 请您参与 -->
		<Participate />
		<Footer />
	</div>
</template>

<script>
import Header from '@/components/main/Header'
import Headers from '@/components/wap/Headers'
import Footer from '@/components/main/Footer'
/* 轮播图 */
import Whool from './main/index/Whool'
/* 崇信概况 */
import Overview from './main/index/Overview'
/* 旅游动态 */
import Tourismdyc from './main/index/Tourismdyc'
/* 热门景点 */
import Hotspots from './main/index/Hotspots'
/* 崇信文化 */
import Culture from './main/index/Culture'
/* 崇信美食 */
import Food from './main/index/Food'
/* 请您参与 */ 
import Participate from './main/index/Participate'

export default {
    name: "Index",
    components: {
		Headers,
		Header,
		Footer,
		/* 轮播图 */
		Whool,
		/* 崇信概况 */
		Overview,
		/* 旅游动态 */
		Tourismdyc,
		/* 热门景点 */
		Hotspots,
		/* 崇信文化 */
		Culture,
		/* 崇信美食 */
		Food,
		/* 请您参与 */ 
		Participate,
	},
};
</script>
